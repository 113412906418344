
<div class="container"> 
  <div class="row">
     
    <div class="col-lg-12">
      <div class="cart_box h80vh" >
        <p class="title">{{util.translate('Lab Test Summary')}}</p> 
        <div class="noData" *ngIf="!cart.cart?.length">
          <img src="assets/images/cart.png" alt="" class="noDataImg">
          <label>{{util.translate('Your Cart is Empty')}}</label>
          <label>{{util.translate('Please add your lab test')}} <a href="/labtest"> click here</a></label>
        </div>
        <div class="row"> 
        <div class="col-lg-8"> 
        <div class="cart_items" *ngIf="cart.cart?.length">
          <div class="labtest-list bgblue">
                <div class=""> <label class="heading_lbl">Test Name </label></div>
                <div class="">  <label class="heading_lbl">Price </label></div> 
          </div>
          <div *ngFor="let item of cart.cart;let i = index">
            <div class="labtest-list" *ngIf="item.quantiy > 0 && !item.selectedItem?.length">
              <!-- <div class="col-2 cover_img"
                [ngStyle]="{'background-image':'url('+api.mediaURL+item.cover+'),url(assets/placeholder.jpg)'}"></div> -->
              <div class="">
                <label class="heading_lbl">
                  {{ (item.name.length>120)? (item.name | slice:0:120)+'..':(item.name) }}
                </label>
                <!-- <div class="Rating">
                  ({{item.rating}} {{util.translate('Ratings')}})
                </div> -->
                <label class="small_lbl">
                  {{ (item.name.length>130)? (item.name | slice:0:130)+'..':(item.name) }}
                </label>
                
              </div>
              <div class="">
                <div class="item-count">
                  <div class="cart_btn"> 
                    <label class="btns" mdbWavesEffect (click)="removeCartQ(i)"><i class="fa fa-trash-o text-danger"></i></label>
                    <!-- <label class="totls">{{item.quantiy}} </label>
                    <label class="btns" mdbWavesEffect (click)="addCartQ(i)">+</label> -->
                  </div>
                  <div class="price-align"> 
                      <label class="heading_lbl" *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.test_rate | number : '.2-2'}}</label>
                      <label class="heading_lbl" *ngIf="util.cside ==='right'">{{item.test_rate | number : '.2-2'}} {{util.currecny}} </label>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="item.quantiy > 0 && item.selectedItem?.length">
              <div class="row" *ngFor="let subItems of item.selectedItem;let j = index">
                <div class="col-2 cover_img"
                  [ngStyle]="{'background-image':'url('+api.mediaURL+item.cover+'),url(assets/placeholder.jpg)'}"></div>
                <div class="col-6">
                  <label class="heading_lbl">{{item.name}}</label>
                  <p *ngFor="let addods of subItems.item" style="margin: 0px;font-size: 10px;color: black;">
                    - {{addods.name}}
                    <span style="color: black;" *ngIf="util.cside ==='left'"> {{util.currecny}} {{addods.value}}</span>
                    <span style="color: black;" *ngIf="util.cside ==='right'"> {{addods.value}} {{util.currecny}}
                    </span>
                  </p>
                </div>
                <div class="col-4">
                  <div class="cart_btn">
                    <label class="btns" mdbWavesEffect (click)="removeAddonCartQ(i,j)">-</label>
                    <label class="totls">{{item.selectedItem[j].total}} </label>
                    <label class="btns" mdbWavesEffect (click)="addAddonCartQ(i,j)">+</label>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <button mdbBtn class="btn btn-primary-sm" (click)="shopmore()">{{util.translate('+ Add Test ')}}</button>
        </div>
        </div>
        <div class="col-lg-4"> 
        <div *ngIf="cart.cart?.length"> 
          <div class="cart_checkout">
            <div class="prices">
              <span class="subTotal">
                {{util.translate('Subtotal')}} :</span>
              <span class="cost" *ngIf="util.cside ==='left'">
                {{util.currecny}} {{cart.totalPrice}}
              </span>
              <span class="cost" *ngIf="util.cside ==='right'">
                {{cart.totalPrice}} {{util.currecny}}
              </span>
            </div>
            <div class="prices" *ngIf="cart.discount && cart.coupon">
              <span class="subTotal">
                {{util.translate('Discount')}} :</span>
              <span class="cost" *ngIf="util.cside ==='left'">
                - {{util.currecny}} {{cart.discount}}
              </span>
              <span class="cost" *ngIf="util.cside ==='right'">
                - {{cart.discount}} {{util.currecny}}
              </span>
            </div>
            <div class="prices">
              <span class="subTotal">
                {{util.translate('Taxes & charges')}} ({{cart.flatTax}} %) :</span>
              <span class="cost" *ngIf="util.cside ==='left'">
                {{util.currecny}} {{cart.orderTax}}
              </span>
              <span class="cost" *ngIf="util.cside ==='right'">
                {{cart.orderTax}} {{util.currecny}}
              </span>
            </div>
            <div class="prices">
              <span class="subTotal">
                {{util.translate('Collection Charge')}} :</span>
              <span class="cost" *ngIf="util.cside ==='left'">
                {{util.currecny}} {{cart.deliveryPrice | number : '.2-2'}}
              </span>
              <span class="cost" *ngIf="util.cside ==='right'">
                {{cart.deliveryPrice | number : '.2-2'}} {{util.currecny}}
              </span>
            </div>
            <div class="prices">
              <span class="grand">
                {{util.translate('Grand Total')}} :</span>
              <span class="cost" *ngIf="util.cside ==='left'">
                {{util.currecny}} {{cart.grandTotal}}
              </span>
              <span class="cost" *ngIf="util.cside ==='right'">
                {{cart.grandTotal}} {{util.currecny}}
              </span>

            </div>
             


            <button mdbBtn class="btn btn-primary" (click)="proceed()">{{util.translate('Proceed / Add Patient ')}}
              <!-- <span *ngIf="util.cside ==='left'">{{util.currecny}} {{cart.grandTotal}} </span>
              <span *ngIf="util.cside ==='right'"> {{cart.grandTotal}} {{util.currecny}} </span> -->

            </button>
          </div>
        </div>
        </div>

      </div>
      </div>
    </div>
  </div>
</div>
